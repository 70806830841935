@import url('https://fonts.googleapis.com/css2?family=Alata&family=Volkhov:wght@400;700&display=swap');
body {
  margin: 0;
 background-color: #f8fafb 
}
@tailwind base;
@tailwind components;
@tailwind utilities;

/* HAMBURGER MENU */

.hamburger {
  cursor: pointer;
  width: 24px;
  height: 24px;
  transition: all 0.25s;
  position: relative;
}

.hamburger-top,
.hamburger-middle,
.hamburger-bottom {
  content: '';
  position: absolute;
  width: 24px;
  height: 3px;
  top: 0;
  left: 0;
  background: #1c1f37;
  transform: rotate(0);
  transition: all 0.5s;
}

.hamburger-middle {
  transform: translateY(7px);
}

.hamburger-bottom {
  transform: translateY(14px);
}

.open {
  transform: rotate(90deg);
  transform: translateY(0px);
}

.open .hamburger-top {
  transform: rotate(45deg) translateY(6px) translateX(6px);
}

.open .hamburger-middle {
  display: none;
}

.open .hamburger-bottom {
  transform: rotate(-45deg) translateY(6px) translateX(-6px);
}

.back-image {
  background: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url('./images/hero.png');

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* height: 80vh; */

  overflow: hidden;
}

@layer base {
  h3 {
    @apply text-4xl font-bold font-sans text-veryLightGray text-center mt-20 xl:text-4xl;
  }
  h5 {
    @apply text-veryLightGray mt-4 text-center max-w-xs font-sans mx-auto xl:max-w-3xl md:mb-4;
  }

  h4 {
    @apply absolute px-6 duration-200 w-52 bottom-4 md:bottom-8 md:px-3 font-sans md:text-xs xl:text-xl xl:px-10 xl:w-72 group-hover:scale-110 group-hover:text-black;
  }
}

.section-center {
  @apply my-16 xl:my-24 max-w-sm sm:max-w-md mx-auto font-sans md:max-w-2xl lg:max-w-5xl xl:max-w-6xl px-4 md:px-6  lg:px-8;
}
